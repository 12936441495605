interface SectionProps {
  title: string
  children: React.ReactNode
}

function Section({ title, children }: SectionProps): React.ReactNode {
  return (
    <div className='flex grow flex-col items-start justify-start gap-2 md:grow-0'>
      <div className='text-sm font-medium uppercase text-lime-600'>{title}</div>

      {children}
    </div>
  )
}

interface ColumnProps {
  children: React.ReactNode
}

function Column({ children }: ColumnProps): React.ReactNode {
  return <div className='flex flex-col gap-1.5 text-sm text-gray-500'>{children}</div>
}

export function Footer(): React.ReactNode {
  return (
    <footer className='px-4-safe pb-8-safe flex flex-col gap-8 border-t pt-6'>
      <div className='grid grid-flow-row grid-cols-2 flex-row flex-wrap items-start justify-between gap-8 lg:container md:flex lg:mx-auto'>
        <Section title='Сайт компании'>
          <Column>
            <a
              href='#'
              className='decoration-gray-300 underline-offset-4 hover:underline'
            >
              baltzdrav.ru
            </a>
          </Column>
        </Section>

        <Section title='Запись на приём'>
          <Column>
            <a
              href='#'
              className='decoration-gray-300 underline-offset-4 hover:underline'
            >
              +7 (812) 325-77-88
            </a>

            <a
              href='#'
              className='decoration-gray-300 underline-offset-4 hover:underline'
            >
              +7 (911) 201-11-33
            </a>
          </Column>
        </Section>

        <Section title='Вызов врача на дом'>
          <Column>
            <a
              href='#'
              className='decoration-gray-300 underline-offset-4 hover:underline'
            >
              +7 (812) 325-77-88
            </a>

            <a
              href='#'
              className='decoration-gray-300 underline-offset-4 hover:underline'
            >
              +7 (911) 201-11-33
            </a>
          </Column>
        </Section>

        <Section title='Расписание работы'>
          <Column>
            <div className='grid grid-cols-[max-content_1fr] gap-x-2'>
              <span>пн.-пт.:</span>
              <span>08:00–21:00</span>
              <span>сб.-вс.:</span>
              <span>09:00–18:00</span>
            </div>
          </Column>
        </Section>
      </div>

      <div className='flex flex-col gap-8 lg:container lg:mx-auto lg:flex-row-reverse'>
        <p className='text-sm text-gray-500 transition-colors lg:container hover:text-gray-500 lg:mx-auto lg:basis-3/4 lg:text-base lg:text-gray-400'>
          Обращаем ваше внимание на то, что вся информация (включая цены) на этом интернет-сайте носит исключительно
          информационный характер и ни при каких условиях не является публичной офертой, определяемой положениями Статьи
          437 (2) Гражданского кодекса РФ.
        </p>

        <ul className='flex flex-col gap-1 text-sm lg:container lg:mx-auto lg:basis-1/4 lg:text-base'>
          <li>
            <a
              href='#'
              className='text-lime-600 decoration-lime-300 underline-offset-4 hover:underline'
            >
              Политика конфиденциальности
            </a>
          </li>
          <li>
            <a
              href='#'
              className='text-lime-600 decoration-lime-300 underline-offset-4 hover:underline'
            >
              Пользовательское соглашение
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}
